import { getRequest, postRequest, urlTitle,getDownLoad } from "@/utils/request";
import qs from "qs";
window.qs = qs;
// 采购计划
export const purchaseApi = {
  // 采购计划列表
  queryPurchase: (params) => {
    return getRequest(`${urlTitle}/purchase/program/page/list`, params);
  },
  // 创建采购计划
  createPurchase: (params) => {
    return postRequest(`${urlTitle}/purchase/program/add`, params);
  },
  // 修改采购计划
  updatePurchase: (params) => {
    return postRequest(`${urlTitle}/purchase/program/update`, params);
  },
  // 删除采购计划
  deletePurchase: (id) => {
    return postRequest(`${urlTitle}/purchase/program/${id}`);
  },
  // 上传文件
  uploadFile: (params) => {
    return postRequest(`${urlTitle}/purchase/program/upload`, params);
  },
  // 查看文件
  viewFile: (id) => {
    return postRequest(`${urlTitle}/purchase/program/file/${id}`);
  },
  // 删除文件
  deleteFile: (id) => {
    return postRequest(`${urlTitle}/sysfile/delete/${id}`);
  },
  // 下载文件
  downloadFile: (params) => {
    return getDownLoad(`${urlTitle}/sysfile/download`,params);
  },
  dialogList:(params)=>{
    return getRequest(`${urlTitle}/purchase/program/file/list`, params);
  }
};

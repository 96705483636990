<!-- 采购计划 -->
<template>
  <div id="purchase">
    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt="" />
        <span>采购计划</span>
      </div>
      <div class="content">
        <div class="query-form">
          <el-form class="form" ref="form" :inline="true" :model="requestParam">
            <el-form-item label="计划采购人">
              <el-input v-model.trim="requestParam.userName" placeholder="请输入计划采购人名称" clearable></el-input>
            </el-form-item>
            <el-form-item label="完成状态">
              <el-select v-model="requestParam.accomplishFlage" placeholder="请选择字典状态" :popper-append-to-body="false"
                clearable>
                <el-option label="已完成" value="0"></el-option>
                <el-option label="未完成" value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="开始时间">
              <el-date-picker type="date" placeholder="选择开始时间" value-format="yyyy-MM-dd"
                v-model="requestParam.createdAtStart" :append-to-body="false" @change="checkDate"></el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间">
              <el-date-picker type="date" placeholder="选择结束时间" value-format="yyyy-MM-dd"
                v-model="requestParam.createdAtEnd" :append-to-body="false" :picker-options="pickerOptions"
                @change="checkDate"></el-date-picker>
            </el-form-item>
          </el-form>
          <div class="query-button">
            <el-button type="primary" icon="el-icon-search" @click="formQuery">查询</el-button>
            <el-button :class="isStringInArray('btnNewcgPlan') ? '' : 'btnShowAuthority'" type="primary" icon="el-icon-plus" @click="createDialog">新建计划</el-button>
          </div>
        </div>
        <el-table class="table" :data="tableData">
          <el-table-column align="center" type="index" label="序号" width="75">
            <template slot-scope="scope">
              <span>{{
            (requestParam.pageNum - 1) * requestParam.pageSize +
            scope.$index +
            1
          }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="purchaseNum" label="采购编号" :fluid="true">
          </el-table-column>
          <el-table-column align="center" prop="purchaseTime" label="采购时间">
          </el-table-column>
          <el-table-column align="center" prop="accomplishFlage" label="是否完成">
            <template slot-scope="scope">
              <span>{{
            scope.row.accomplishFlage === 0 ? "已完成" : "未完成"
          }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="buyer" label="计划采购人">
          </el-table-column>
          <el-table-column align="center" prop="userName" label="创建人">
          </el-table-column>
          <el-table-column align="center" prop="createTime" label="创建时间" width="175">
          </el-table-column>
          <el-table-column align="center" prop="remark" label="备注" width="200">
            <template slot-scope="scope">
              <template v-if="isOverflow(scope.row.remark)">
                <el-tooltip :content="scope.row.remark" placement="top" :popper-class="'tooltip'">
                  <div class="ellipsis">{{ scope.row.remark }}</div>
                </el-tooltip>
              </template>
              <template v-else>
                <div class="ellipsis">{{ scope.row.remark }}</div>
              </template>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" prop="operate" width="250">
            <template slot-scope="scope">
              <div class="operate">
                <el-button :class="isStringInArray('btnLookFile') ? '' : 'btnShowAuthority'" type="text" @click="viewDialog(scope.row)">查看文件</el-button>
                <span :class="isStringInArray('btnLookFile') ? '' : 'btnShowAuthority'">|</span>
                <el-button :class="isStringInArray('btnEdit') ? '' : 'btnShowAuthority'" type="text" @click="updateDialog(scope.row)">编辑</el-button>
                <span :class="isStringInArray('btnEdit') ? '' : 'btnShowAuthority'">|</span>
                <el-button :class="isStringInArray('btnDelete') ? '' : 'btnShowAuthority'" type="text" @click="deleteDialog(scope.row)">删除</el-button>
                <span :class="isStringInArray('btnDelete') ? '' : 'btnShowAuthority'">|</span>
                <el-button :class="isStringInArray('btnUpLoadFile') ? '' : 'btnShowAuthority'" type="text" @click="uploadDialog(scope.row)">上传文件</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <div class="home-page" @click="homePage">首页</div>
          <el-pagination :current-page="requestParam.pageNum" :page-sizes="[10, 20, 30, 40]"
            layout="prev, pager, next, sizes,slot" prev-text="上一页" next-text="下一页" :total="total" background
            @size-change="changeSize" @current-change="changeCurrent">
          </el-pagination>
          <div class="tail-page" @click="tailPage">尾页</div>
          <div class="jump-pagination">
            <span class="text">跳至</span>
            <el-input size="mini" class="pagination-input" v-model.number="inputValue" @input="checkPageNum"
              @change="inputValueChange"></el-input>
            <span class="text">页</span>
            <div class="confirm" @click="confirm">确定</div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :title="requestType === 0 ? '新建采购计划' : '修改采购计划'" :visible.sync="dialogVisible" width="556px"
      @close="dialogClose('planForm')">
      <div class="dialog-content">
        <el-form class="dialog-form" ref="planForm" :inline="true" :model="planData" :rules="rules">
          <el-form-item label="采购时间" prop="purchaseTime">
            <el-date-picker type="date" placeholder="请选择采购时间" value-format="yyyy-MM-dd" v-model="planData.purchaseTime"
              :append-to-body="false"></el-date-picker>
          </el-form-item>
          <el-form-item label="采购人" prop="buyer">
            <el-input v-model.trim="planData.buyer" placeholder="请输入采购人"></el-input>
          </el-form-item>
          <el-form-item v-if="requestType === 1" label="完成状态" prop="accomplishFlage">
            <el-radio-group v-model="planData.accomplishFlage">
              <el-radio :label="0">完成</el-radio>
              <el-radio :label="1">未完成</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="备注">
            <el-input type="textarea" :rows="3" v-model="planData.remark"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogConfirm('planForm')">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="deleteType === 0
            ? '删除计划'
            : deleteType === 1
              ? '删除文件'
              : '下载文件'
            " :visible.sync="deleteVisible" width="436px">
      <div class="dialog-text">
        {{
            deleteType === 0  ? "确定要删除该数据吗？" : deleteType === 1 ? "确定要删除该文件吗？" : "确定要下载该文件吗？"
          }}
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="deleteVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteConfirm">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="上传文件" :visible.sync="uploadVisible" width="600px" @close="dialogClose('planForm')">
      <div class="upload-dialog">
        <el-form class="dialog-form" ref="planForm" :inline="true" :model="planData" :rules="rules">
          <el-form-item label="批量上传" prop="file">
            <el-upload action="" :show-file-list="true" :auto-upload="false" :on-remove="handleRemove" multiple
              :limit="9" :file-list="fileList" :on-change="fileChange">
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload-tip">
                批量上传一次最多添加9个附件,单个附件最大不超过30M
              </div>
            </el-upload>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="uploadVisible = false">取 消</el-button>
        <el-button type="primary" :disabled="file.length == 0" @click="uploadFile('planForm')">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="查看文件" :visible.sync="viewVisible" width="876px">
      <div class="file-dialog">
        <el-table class="table" :data="fileData" v-loading="loading" element-loading-text="加载中"
          element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.3)">
          <el-table-column align="center" type="index" label="序号" width="75">
          </el-table-column>
          <el-table-column align="center" prop="originalFileName" label="名称">
          </el-table-column>
          <el-table-column align="center" prop="size" label="大小">
          </el-table-column>
          <!-- <el-table-column align="center" prop="suffix" label="类型">
          </el-table-column> -->
          <!-- <el-table-column align="center" prop="userName" label="创建人">
          </el-table-column> -->
          <el-table-column align="center" prop="createTime" label="创建时间">
          </el-table-column>
          <el-table-column align="center" label="操作" prop="operate" width="100">
            <template slot-scope="scope">
              <div class="operate">
                <el-button type="text" @click="fileDialog(scope.row)">删除</el-button>
                <span>|</span>
                <el-button type="text" @click="downloadDialog(scope.row)">下载</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <button class="home-page" @click="homePageDialog">首页</button>
          <el-pagination :current-page="dialogForm.pageNum" :page-sizes="[10, 20, 30, 40]"
            layout="prev, pager, next, sizes,slot" prev-text="上一页" next-text="下一页" :total="totalDialog" background
            @size-change="changeSizeDialog" @current-change="changeCurrentDialog">
          </el-pagination>
          <button class="tail-page" @click="tailPageDialog">尾页</button>
          <div class="jump-pagination">
            <span class="text">跳至</span>
            <el-input size="mini" class="pagination-input" v-model.number="inputValueDialog" @input="checkPageNumDialog"
              @change="inputValueChangeDialog"></el-input>
            <span class="text">页</span>
            <button class="confirm" @click="confirmDialog">确定</button>
          </div>
        </div>

      </div>
      <div slot="footer" class="dialog-footer">
        <!-- <el-button @click="viewVisible = false">取 消</el-button>
        <el-button type="primary" @click="viewVisible = false">确 定</el-button> -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { purchaseApi } from "@/api/purchase.js";
export default {
  name: "",
  data() {
    const mustUpload = (rule, value, callback) => {
      if (this.file.length === 0) {
        callback("请上传文件");
      }
      callback();
    };
    return {
      requestParam: {
        pageNum: 1,
        pageSize: 10,
        createdAtStart: null,
        createdAtEnd: null,
        accomplishFlage: "",
      },
      total: 0,
      inputValue: 1,
      dialogForm:{
        id:'',
        pageNum:1,
        pageSize:10,
      },
      totalDialog:0,
      inputValueDialog:1,
      requestType: 0,
      deleteType: 0,
      tableData: [],
      fileData: [],
      loading: false,
      rules: {
        purchaseTime: [
          { required: true, message: "请选择采购时间", trigger: "change" },
        ],
        buyer: [{ required: true, message: "请输入采购人", trigger: "blur" }],
        accomplishFlage: [
          { required: true, message: "请选择完成状态", trigger: "change" },
        ],
        file: [{ validator: mustUpload, trigger: "change" }],
      },
      viewVisible: false,
      deleteVisible: false,
      dialogVisible: false,
      uploadVisible: false,
      id: "",
      planData: {
        purchaseTime: "",
        buyer: "",
        remark: "",
        accomplishFlage: "",
      },
      defaultData: {
        purchaseTime: "",
        buyer: "",
        remark: "",
        accomplishFlage: "",
      },
      file: [],
      fileList: [],
      fileId: "",
      fileName: "",
      authorityListBtn:[],
    };
  },
  created() {
    this.$store.commit("increment", "计划管理");
    this.$store.commit("selectChild", "采购计划");
    this.$store.commit("selectChildren", "");
  },
  mounted() {
    this.queryPurchase();
    this.authorityListBtn=sessionStorage.getItem('roleInfo')
  },
  methods: {
     //按钮权限
     isStringInArray(str) {
      if (this.authorityListBtn) {
        let list = this.authorityListBtn.includes(str);
        return list
      } else {
        return false
      }
    },
    isOverflow(content) {
      if (content) {
        const lineHeight = 16;
        const numLines = Math.ceil(content.length / 42); // 每行大概显示42个字符
        // console.log("====>",numLines);
        return numLines >= 3;
      }
    },

    handleRemove(file, fileList) {
      this.file = fileList.map((item) => item.raw);
    },
    fileChange(file, fileList) {
      this.file = fileList.map((item) => item.raw);
    },
    dialogClose(formName) {
      this.fileList = [];
      this.planData = {
        purchaseTime: "",
        buyer: "",
        remark: "",
        accomplishFlage: "",
      },
        // Object.assign(this.planData, this.defaultData);
        this.$refs[formName].resetFields();
    },
    dialogConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.requestType === 0) {
            this.createPurchase();
          } else {
            this.updatePurchase();
          }
        } else {
          return false;
        }
      });
    },
    checkDate() {
      if (this.requestParam.createdAtStart && this.requestParam.createdAtEnd) {
        const startTime = new Date(this.requestParam.createdAtStart).getTime();
        const endTime = new Date(this.requestParam.createdAtEnd).getTime();
        if (startTime > endTime) {
          this.requestParam.createdAtEnd = null;
        }
      }
    },
    formQuery() {
      this.requestParam.pageNum = 1;
      this.queryEnergyStation();
    },
    uploadDialog(item) {
      this.id = item.id;

      this.uploadVisible = true;
    },

    createDialog() {
      this.requestType = 0;
      Object.assign(this.planData, this.defaultData);
      this.dialogVisible = true;
    },
    updateDialog(data) {
      this.requestType = 1;
      this.id = data.id;
      this.planData.purchaseTime = data.purchaseTime;
      this.planData.buyer = data.buyer;
      this.planData.remark = data.remark;
      this.planData.accomplishFlage = data.accomplishFlage;
      this.dialogVisible = true;
    },
    viewDialog(data) {
      this.id = data.id;
      this.dialogForm.id=data.id
      this.queryDialogList();
      this.viewVisible = true;
    },
    deleteDialog(data) {
      this.id = data.id;
      this.deleteType = 0;
      this.deleteVisible = true;
    },
    fileDialog(data) {
      this.fileId = data.id;
      this.deleteType = 1;
      this.deleteVisible = true;
    },
    downloadDialog(data) {
      this.fileId = data.id;
      this.fileName = data.originalFileName;
      this.deleteType = 2;
      this.deleteVisible = true;
    },
    deleteConfirm() {
      if (this.deleteType === 0) {
        this.deletePurchase();
      } else if (this.deleteType === 1) {
        this.deleteFile();
      } else {
        this.downloadFile();
      }
    },
    formQuery() {
      this.requestParam.pageNum = 1;
      this.queryPurchase();
    },
    checkPageNum(e) {
      let value = e.replace(/[^\d]/g, ""); // 只能输入数字
      value = value.replace(/^0+(\d)/, "$1"); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      this.inputValue = value
    },
    //页数输入
    inputValueChange() {
      const lastPage = Math.ceil(this.totalDialog / this.requestParam.pageSize);
      if (this.inputValue < 1) {
        this.inputValue = 1;
      } else {
        this.inputValue =
          this.inputValue < lastPage ? this.inputValue : lastPage;
      }
    },
    //首页
    homePage() {
      this.requestParam.pageNum = 1;
      this.queryPurchase();
    },
    //尾页
    tailPage() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      this.requestParam.pageNum = lastPage;
      this.queryPurchase();
    },
    confirm() {
      this.requestParam.pageNum = this.inputValue;
      this.queryPurchase();
      // this.inputValue = ""
    },
    changeSize(pageSize) {
      this.requestParam.pageSize = pageSize;
      this.queryPurchase();
    },
    changeCurrent(pageNum) {
      this.requestParam.pageNum = pageNum;
      this.queryPurchase();
    },
    /**
     * dialog分页
     * */ 
     checkPageNumDialog(e) {
      let value = e.replace(/[^\d]/g, ""); // 只能输入数字
      value = value.replace(/^0+(\d)/, "$1"); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      this.inputValueDialog = value
    },
    //页数输入
    inputValueChangeDialog() {
      const lastPage = Math.ceil(this.totalDialog / this.requestParam.pageSize);
      if (this.inputValueDialog < 1) {
        this.inputValueDialog = 1;
      } else {
        this.inputValueDialog =
          this.inputValueDialog < lastPage ? this.inputValueDialog : lastPage;
      }
    },
    //首页
    homePageDialog() {
      this.dialogForm.pageNum = 1;
      this.queryDialogList();
    },
    //尾页
    tailPageDialog() {
      const lastPage = Math.ceil(this.totalDialog / this.dialogForm.pageSize);
      this.dialogForm.pageNum = lastPage;
      this.inputValueDialog=lastPage
      this.queryDialogList();
    },
    confirmDialog() {
      this.dialogForm.pageNum = parseInt(this.inputValueDialog) ;
      this.queryDialogList();
      // this.inputValueDialog = ""
    },
    changeSizeDialog(pageSize) {
      this.dialogForm.pageSize = pageSize;
      this.queryDialogList();
    },
    changeCurrentDialog(pageNum) {
      this.dialogForm.pageNum = pageNum;
      this.inputValueDialog=pageNum
      this.queryDialogList();
    },
    queryDialogList(){
      this.loading = true;
      purchaseApi.dialogList(this.dialogForm).then(res=>{
        this.loading = false;
        this.fileData=res.data
        this.totalDialog=res.total
      })
    },
    uploadFile(formName) {
      let formData = new FormData();
      for (let i = 0; i < this.file.length; i++) {
        formData.append("multipartFiles", this.file[i]);
      }
      formData.append("dataId", this.id);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          purchaseApi.uploadFile(formData).then((res) => {
            if (res.code === 200) {
              this.$message({
                message: res.message,
                type: "success",
                duration: 3000,
                customClass: "messageText",
              });
              this.uploadVisible = false;
              this.queryPurchase();
            } else {
              this.$message({
                message: res.message,
                type: "error",
                duration: 3000,
                customClass: "messageText",
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    downloadFile() {
      purchaseApi.downloadFile({ id: this.fileId }).then((res) => {
        console.log(res);
        const link = document.createElement("a");
        // const filename = res.headers['Content-Disposition'].split('filename=')[1]; // 获取文件名称
        link.href = URL.createObjectURL(new Blob([res]));
        link.download = decodeURIComponent(this.fileName); // 解码文件名称，避免名称中含有中文时乱码
        link.click();
        this.deleteVisible = false;
      });
    },
    // viewFile() {
    //   this.loading = true;
    //   purchaseApi.viewFile(this.id).then((res) => {
    //     this.loading = false;
    //     if (res.code === 200) {
    //       this.fileData = res.data;
    //     }
    //     if (res.code === 500) {
    //       this.fileData = [];
    //     }
    //   });
    // },
    deleteFile() {
      purchaseApi.deleteFile(this.fileId).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: res.message,
            type: "success",
            duration: 3000,
            customClass: "messageText",
          });
          this.deleteVisible = false;
          this.queryDialogList();
        } else {
          this.$message({
            message: res.message,
            type: "error",
            duration: 3000,
            customClass: "messageText",
          });
        }
      });
    },
    queryPurchase() {
      purchaseApi.queryPurchase(this.requestParam).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data;
          this.total = res.total;
        }
      });
    },
    createPurchase() {
      purchaseApi.createPurchase(this.planData).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: res.message,
            type: "success",
            duration: 3000,
            customClass: "messageText",
          });
          this.dialogVisible = false;
          this.queryPurchase();
        } else {
          this.$message({
            message: res.message,
            type: "error",
            duration: 3000,
            customClass: "messageText",
          });
        }
      });
    },
    updatePurchase() {
      this.planData.id = this.id;
      purchaseApi.updatePurchase(this.planData).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: res.message,
            type: "success",
            duration: 3000,
            customClass: "messageText",
          });
          this.dialogVisible = false;
          this.queryPurchase();
        } else {
          this.$message({
            message: res.message,
            type: "error",
            duration: 3000,
            customClass: "messageText",
          });
        }
      });
    },
    deletePurchase() {
      purchaseApi.deletePurchase(this.id).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: res.message,
            type: "success",
            duration: 3000,
            customClass: "messageText",
          });
          this.deleteVisible = false;
          this.queryPurchase();
        } else {
          this.$message({
            message: res.message,
            type: "error",
            duration: 3000,
            customClass: "messageText",
          });
        }
      });
    },
  },
  computed: {
    pickerOptions() {
      const _this = this;
      return {
        disabledDate(time) {
          const limitDate = new Date(_this.requestParam.createdAtStart);
          limitDate.setHours(0, 0, 0, 0); // 重置时间为当天的开始时间

          return time.getTime() < limitDate.getTime();
        },
      };
    },
  },
};
</script>
<style src="@/utils/style/public-style.css" scoped></style>
<style scoped>
#purchase {
  width: 100%;
  background: url("../../assets/images/rightBG.png") no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
}

.main {
  width: 100%;
  padding: 20px 26px 28px 25px;
  box-sizing: border-box;
}

/* dialog form */
.dialog-content {
  margin: 30px 32px 0 27px;
}

.dialog-form :deep().el-form-item__label {
  width: 69px;
  color: #c9d4f1;
  font-size: 12px;
  text-align: right;
}

.dialog-form :deep() .el-date-editor {
  width: 406px;
}

.dialog-form :deep() .el-input .el-input__inner,
.dialog-form :deep() .el-textarea textarea {
  width: 406px;
  color: #c9d4f1;
  font-size: 12px;
  border: 1px solid #06224e;
  background-color: rgba(255, 255, 255, 0);
}

.upload-dialog {
  margin: 42px 0 64px 40px;
}

.file-dialog {
  margin: 16px;
}

.el-upload-tip {
  color: #e8f4ff;
  font-size: 12px;
}
</style>
<style>
.tooltip {
  max-width: 600px;
  /* 设置最大宽度 */
  line-height: 20px;
}
</style>
